module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"93f09d6440f9be85886132faee6f02e3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-i18n-l10n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLocale":"en-GB","siteUrl":"https://sharon-in.tech","locales":[{"locale":"en-GB","prefix":"en","slugs":{},"messages":{"navHome":"Home","navAbout":"About","navResume":"Resume","navContact":"Contact","heroSubTitle":"Driven and ambitious professional with a passion for IT","heroChangeLanguage":"NL","heroChangeLanguageSR":"Change language to Dutch","aboutTitle":"My profile","aboutSubTitle":"Get to know more about me","aboutConnect":"Connect with me","experienceTitle":"My Resume","experienceHeadingWork":"Work experience","experienceHeadingEducation":"Education","contactTitle":"Contact me","contactSubTitle":"Let's get in touch","contactFormPlaceholderSubject":"Subject","contactFormPlaceholderName":"Your name","contactFormPlaceholderEmail":"Your email","contactFormPlaceholderMessage":"Drop message","contactFormSubmit":"Send!"}},{"locale":"nl-NL","prefix":"nl","slugs":{},"messages":{"navHome":"Home","navAbout":"Profiel","navResume":"CV","navContact":"Contact","heroSubTitle":"Gedreven em ambitieuze professional met een passie voor IT","heroChangeLanguage":"EN","heroChangeLanguageSR":"Change language to Dutch","aboutTitle":"Mijn profiel","aboutSubTitle":"Kom meer over me te weten","aboutConnect":"Voeg me toe aan je netwerk","experienceTitle":"Mijn CV","experienceHeadingWork":"Werkervaring","experienceHeadingEducation":"Opleidingen","contactTitle":"Contact","contactSubTitle":"Kom met me in contact","contactFormPlaceholderSubject":"Onderwerp","contactFormPlaceholderName":"Je naam","contactFormPlaceholderEmail":"Je emailadres","contactFormPlaceholderMessage":"Jouw bericht","contactFormSubmit":"Verstuur!"}}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
